import UploadSummaryModel from '@/VueComponents/Uploads/models/uploadSummaryModel';
import UploadMetadata from '@/VueComponents/Uploads/models/uploadMetadata';
import UploadFieldValue from '@/VueComponents/Uploads/models/uploadFieldValue';
import ValidationState from '@/VueComponents/Uploads/models/validationState';
import FileNameManager from '@/Utils/fileNameManager';
import UploadManager from '@/Utils/uploadManager';
import ConfigurableField from '@/Types/configurableField';
import { ConfigurableFieldType } from '@/Types/Enums/configurableFieldType';
import { ConfigurableFieldName } from '@/Types/Enums/configurableFieldName';
import UploadMediaTypeFileExtensions from '@/Types/uploadMediaTypeFileExtensions';
import UploadField from '@/Types/uploadField';
import constants from '@/constants';

export default class MediaTypeConfigurableFieldManager {
  static fillMetadataForMediaType(fields: ConfigurableField[], uploadsMetadata: UploadMetadata[],
    uploads: UploadSummaryModel[], uploadMediaTypesFileExtensions: UploadMediaTypeFileExtensions[]): UploadField[] {
    const archiveMediaType = 'Archive';
    const readonlyFieldForUploads: UploadField[] = [];

    const mediaTypeField = fields.find(f =>
      f.type === ConfigurableFieldType.hierarchical && f.name === ConfigurableFieldName.mediaType && f.options);
    if (!mediaTypeField) {
      return readonlyFieldForUploads;
    }

    uploads.forEach((upload: UploadSummaryModel) => {
      // check if metadata for hierarchical field exists
      // if not then it's needed to check if it could be prefilled based on the file extension or file type
      let metadata = uploadsMetadata.find(metadata => metadata.uploadId === upload.id);
      if (!metadata) {
        metadata = new UploadMetadata(upload.id, []);
        uploadsMetadata.push(metadata);
      }
      const existedFieldValue = metadata.uploadFieldValues.find(x => x.fieldId === mediaTypeField.id);
      if (!existedFieldValue) {
        if (UploadManager.isUploadArchive(upload.type)) {
          const archiveDefaultSubOption = mediaTypeField.options?.find(x => x.value === archiveMediaType && x.isDefaultSubGroupValue);
          const subValue = archiveDefaultSubOption?.subGroupValue ?? null;
          readonlyFieldForUploads.push({ fieldId: mediaTypeField.id, uploadId: upload.id });
          metadata.uploadFieldValues.push(new UploadFieldValue(mediaTypeField.id, archiveMediaType, subValue,
            new ValidationState(mediaTypeField.isMandatory ? (subValue !== null) : true, '')));
        } else {
          const extension = FileNameManager.getExtension(upload.name).toLowerCase();
          // try to find criteria according to upload file extension
          const criteria = uploadMediaTypesFileExtensions.find(mt => mt.fileExtensions.map(x => x.toLowerCase()).includes(extension));
          let mediaType = criteria?.mediaType;
          // fallback to 'File' media type if no extension matched
          if (!mediaType) {
            mediaType = constants.defaultUploadMediaType;
          }
          // Parent Media Type field should be readonly if it is auto-selected from seeded Media Types
          readonlyFieldForUploads.push({ fieldId: mediaTypeField.id, uploadId: upload.id });
          const parentValue = mediaType;
          let subValue = null;
          // find options according to extension criteria
          const mediaTypeOptions = mediaTypeField.options?.filter(option => option.value === mediaType);
          if (mediaTypeOptions?.length) {
            const optionWithMatchingExtensions = mediaTypeOptions.find(option =>
              option.matchingExtensions?.map(x => x.toLowerCase())?.includes(extension));
            if (optionWithMatchingExtensions && optionWithMatchingExtensions.subGroupValue) {
              subValue = optionWithMatchingExtensions.subGroupValue;
            } else {
              const defaultSubOption = mediaTypeOptions.find(option => option.isDefaultSubGroupValue);
              if (defaultSubOption && defaultSubOption.subGroupValue) {
                subValue = defaultSubOption.subGroupValue;
              }
            }
          }

          metadata.uploadFieldValues.push(new UploadFieldValue(mediaTypeField.id, parentValue, subValue,
            new ValidationState(mediaTypeField.isMandatory ? (subValue !== null) : true, '')));
        }
      } else {
        readonlyFieldForUploads.push({ fieldId: mediaTypeField.id, uploadId: upload.id });
      }
    });

    return readonlyFieldForUploads;
  }
}